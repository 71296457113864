import React, { useEffect } from "react"
import SEO from "../components/metaSEO"
import { FadeWrapper } from "../components/Transitions"
import { useFrontpage } from "../utils/frontpageContext"
import {Video} from "../components/vroom"

const IndexPage = ({ location }) => {
  const { setFrontpage } = useFrontpage()
  useEffect(() => {
    // Set frontpage to true on mount
    setFrontpage(true)
    return () => {
      // Set frontpage to false on unMount
      setFrontpage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <FadeWrapper>
      <SEO />
      <Video />
    </FadeWrapper>
  )
}

export default IndexPage
