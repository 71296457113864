import React from "react"
import img from "../../images/vroom.jpeg"
import video from "../../video/optimised.mp4"
import { Link } from "../Transitions"
import styled from "styled-components"

const Vroom = () => {
  return (
    <>
      <Button to="/vr-exhibition">Take VR Tour</Button>
      <BackgroundImage image={img} />
    </>
  )
}

export const Video = () => {
  return (
    <>
      <Button to="/vr-exhibition">Take VR Tour</Button>
      <VideoEmbed
        autoPlay={true}
        muted={true}
        loop={true}
        playsInline={true}
        poster={img}
        width="100%"
      >
        <source src={video} type="video/mp4" />
        <BackgroundImage image={img} />
      </VideoEmbed>
    </>
  )
}

const Button = styled(Link)`
  width: 180px;
  height: 180px;
  background: yellow;
  position: fixed;
  top: 66%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.3s ease opacity;
  &:hover {
    opacity: 0.9;
    color: black;
  }

  @media (min-width: 740px) {
    top: 50%;
  }
`

const VideoEmbed = styled.video`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: -1;
`

const BackgroundImage = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  background: url(${props => props.image}) center / cover no-repeat;
`

export default Vroom
